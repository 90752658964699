import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { HttpHelpService } from "../../service/http-help.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DatePipe } from "@angular/common";
import { EMPTY, Observable, Subject, catchError, concatMap, flatMap, map, switchMap, tap, throwError, timer } from "rxjs";
import { of } from 'rxjs/internal/observable/of';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { AddCustomerEndPoient, AddInvoiceEndPoient, AddProductEndPoient } from "../../service/global.service";
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";
import { israelPhoneNumberValidator } from "src/app/validators/israelPhoneNumberValidator";
@Component({
  selector: "app-add-offer-price",
  templateUrl: "./add-offer-price.component.html",
  styleUrls: ["./add-offer-price.component.scss"]
})

export class AddOfferPriceComponent implements OnInit {
  
  vatTypeList: any;
  vatPersentageData: any;
  VatPercentage: any;
  invoiceId: any;
  vatListId: number;
  today: any;
  mastercompany: any;
  oneCompanyData: any=[];
  oneCustomerData: any=[];
  id:number;
  masterInvoiceTypeList: any;
  curentLang:string="";
  userRegistId:any = JSON.parse(localStorage.getItem('user_id'));
  merchantId = JSON.parse(localStorage.getItem('merchant_id'));
  user_type = JSON.parse(localStorage.getItem('user_type'));
  companyDefault = JSON.parse(localStorage.getItem('companyDefault'));
  CompanyNameEn:string = '';
  CompanyNameAr:string = '';
  CompanyNameHe:string = '';

  CustomerNameEn:string = '';
  CustomerNameAr:string = '';
  CustomerNameHe:string = '';

  selectedProduct:any;
  selectedMeasurementUnit:any;
  CompanyAddress:string = '';
  TaxCompany:string = '';
  customerVatNumber:string='';
  LOGO:string='';
  companyId:any;
  CompanyAddress_zip_code: any;
  CompanyAddress_city: any;
  CompanyAddress_number: any;
  CompanyAddress_str: any;
  tokenApi: any;
  refreshSubscription: any;
  sendToGovernment: any;
  updateToken: Object;
  uuId:any;
  addCustomer:FormGroup;
  public validate = false;
  customerTypeData:any=[];
  addProduct:FormGroup;
  categoriesData:any=[];
  unitMesauereData:any=[];
  selectedMonth:any
  selectedYear:any
  public validate2 = false;

  constructor(private builder: FormBuilder, private service: HttpHelpService, private router: Router, private alert: ToastrService,
    private activeroute: ActivatedRoute,private modalService: NgbModal, private spinner: NgxSpinnerService,  private datePipe: DatePipe ,
    private toastr: ToastrService ,
    private fb: FormBuilder

  )
   { 
    this.curentLang = localStorage.getItem('curentLang');
    this.companyDefault = localStorage.getItem('companyDefault');

    this.addCustomer = this.fb.group({
      customer_name_he: ['', Validators.required],
      customer_name_en: ['',],
      customer_name_ar: ['', ],
      customer_type: ['', Validators.required],
      customer_phone: [''],
      id_number: [null],
      customer_vat_number: [null],
      customer_vat_tax: [null],
      customer_address_str: [''],  // جعل هذا الحقل اختياريًا
      customer_address_str_ar: [''],  // جعل هذا الحقل اختياريًا
      customer_address_str_en: [''],  // جعل هذا الحقل اختياريًا

      customer_address_city: [''], // جعل هذا الحقل اختياريًا
      customer_address_city_ar: [''], // جعل هذا الحقل اختياريًا
      customer_address_city_en: [''], // جعل هذا الحقل اختياريًا

      customer_address_zip_code: [''], // جعل هذا الحقل اختياريًا
      customer_Building_number: [''], // جعل هذا الحقل اختياريًا
    });


      this.addProduct=new FormGroup({
        product_name_en :new FormControl('' , Validators.required),
        product_name_ar:new FormControl('' , Validators.required),
        product_name_he :new FormControl('' , Validators.required),  
        des_ar :new FormControl('' , Validators.required), 
        des_en :new FormControl('' , Validators.required), 
        des_he :new FormControl('' , Validators.required), 
        product_code :new FormControl('' , Validators.required),
     //   price :new FormControl('' , Validators.required),  
        price :new FormControl(0), 
        measurement_unit_id :new FormControl('' , Validators.required),  
        categories_id :new FormControl('' , Validators.required),  
        merchant_id :new FormControl(this.merchantId),  
        created_by:new FormControl(this.userRegistId),
        });

   }
  pagetitle = "Create Invoice"
  invoicedetail !: FormArray<any>;
  invoiceproduct !: FormGroup<any>;

  mastercustomer: any;
  masterproduct: any;
  editinvoiceno: any;
  isedit = false;
  editinvdetail: any;
  saveInvoiceButtonClicked: boolean = false;
  companyNameAr :any;
  companyNameEn :any;
  companyNameHe :any;
    selectedCompany:any;

  getAllActiveCategories() {
    this.spinner.show();
    this.service.getAllActiveCategories().subscribe({
      next: res => {
        this.categoriesData = res;
         
        this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  };

  getAllActiveMeasurementUnits() {
    this.spinner.show();
    this.service.getAllActiveMeasurementUnits().subscribe({
      next: res => {
        this.unitMesauereData = res;
         this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  };

  filterProduct(event:any)
  {
      this.selectedProduct = event;
     
   };

   filterMeasurementUnit(event:any)
  {
      this.selectedMeasurementUnit = event;
     
   };

  ngOnInit(): void {
    this.curentLang = localStorage.getItem('curentLang');
    this.companyNameAr = localStorage.getItem('company_name_ar');
    this.companyNameEn  = localStorage.getItem('company_name_en');
    this.companyNameHe  = localStorage.getItem('company_name_he');

    if (this.companyDefault) {
      this.selectedCompany = this.companyDefault;
      
      if (this.curentLang == 'ar'){
        this.selectedCompany = this.companyNameAr;
        }
         else if (this.curentLang === 'he') {
          this.selectedCompany = this.companyNameHe;
        } 

        else if (this.curentLang === 'en') {
          this.selectedCompany = this.companyNameEn;
        } 

        else {
          this.curentLang = 'en';
        }
      

    }
    // تعيين اللغة الافتراضية استنادًا إلى لغة الموقع الحالية
    if (this.curentLang === 'ar') {
      this.curentLang = 'ar'; // لغة الموقع العربية
    } else if (this.curentLang === 'he') {
      this.curentLang = 'he'; // لغة الموقع العبرية
    } else {
      this.curentLang = 'en'
    }


   
      // this.startAutomaticTokenRefresh();
    

             // تحديث النموذج بالقيم المستلمة
             this.invoiceform.patchValue({
              vat_list_id: 8,
    
            });
    
            this.getVatPercentageByVatListIdAndDate()
    
 //   this.invoiceform.get('document_language')?.setValue(this.curentLang);
 const today = new Date();
 this.selectedMonth = today.getMonth() + 1;  
 this.selectedYear = today.getFullYear();  
 this.checkClosedMonth(); 
 this.onCustomerTypeChange();
    this.GetCustomers();
    this.GetProducts();
    this.vatTypechange();
    this.GetCompany();
    this.GetInvoiceTypeList();
    this.getCustomerById();
    this.getCompanyInfoById();
    this.getAllCustomerType();
    this.getAllActiveCategories();
    this.getAllActiveMeasurementUnits();
    this.editinvoiceno = this.activeroute.snapshot.paramMap.get('invoiceno');
    if (this.editinvoiceno != null) {
      this.pagetitle = "Edit Invoice";
      this.isedit = true;
      // this.SetEditInfo(this.editinvoiceno);
    }
    this.userRegistId = JSON.parse(localStorage.getItem('user_id'));
    this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
    this.user_type = JSON.parse(localStorage.getItem('user_type'));
    this.companyDefault = localStorage.getItem('companyDefault');
    if (this.companyDefault !== null && this.companyDefault !== 'null') {
      this.invoiceform.patchValue({
        company_id: Number(this.companyDefault),
      });
      this.checkSequenceExists(Number(this.companyDefault));
    }

    

    this.invoiceform.get('company_id').valueChanges.subscribe(companyId => {
      if (companyId) {
        this.checkSequenceExists(companyId);
      }
    });

  //   this.addCustomer.get('customer_type')?.valueChanges.subscribe((newType: number) => {
  //     this.resetForm();
  // });
}

// resetForm() {
//   // إعادة تعيين النموذج إلى حالته الأولية
//   this.addCustomer.reset({
//       // customer_name_en: null,
//       // customer_name_ar: null,
//       customer_vat_number: null,
//       customer_vat_tax: null,
//       id_number: null,
//       customer_type: this.addCustomer.get('customer_type')?.value, // الاحتفاظ بالقيمة الحالية لـ `customer_type`
//   });
//   };
  openXl(content:any) {
    this.modalService.open(content, { size: 'xl' , centered:true });
  };

  filterCustomer(event:any)
  {
    let x = event.target.value;
   };
  getAllCustomerType() {
    this.spinner.show();
    this.service.getAllCustomerType().subscribe({
      next: res => {
        this.customerTypeData = res;
         
        this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  };
  onCustomerTypeChange() {
    this.addCustomer.get('customer_type')?.valueChanges.subscribe(value => {
      if (value == 5) {
    //    this.addCustomer.get('id_number')?.setValidators([Validators.required, Validators.maxLength(9)]);
        this.addCustomer.get('customer_vat_number')?.clearValidators();
      } else if (value == 6) {
        this.addCustomer.get('customer_vat_number')?.setValidators([Validators.required, Validators.pattern('[0-9]{9}')]);
    //    this.addCustomer.get('id_number')?.clearValidators();
      }
   //   this.addCustomer.get('id_number')?.updateValueAndValidity();
      this.addCustomer.get('customer_vat_number')?.updateValueAndValidity();
    });
  }
  addedData() {
 
    if (this.addCustomer.invalid) {
        this.validate = true;  // تفعيل عرض رسائل الفالديشن
         Object.keys(this.addCustomer.controls).forEach(key => {
            const control = this.addCustomer.get(key);
            if (control.invalid) {
             }
        });
        return;
    }

    // نسخ قيمة customer_name_he إلى customer_name_en و customer_name_ar
    const customerNameHe = this.addCustomer.get('customer_name_he').value;
    this.addCustomer.patchValue({
        customer_name_en: customerNameHe,
        customer_name_ar: customerNameHe
    });

    const customerAddressHe = this.addCustomer.get('customer_address_city').value;
    this.addCustomer.patchValue({
      customer_address_city_en: customerAddressHe,
      customer_address_city_ar: customerAddressHe
    });

    const customerAddressStreetHe = this.addCustomer.get('customer_address_str').value;
    this.addCustomer.patchValue({
      customer_address_str_en: customerAddressStreetHe,
      customer_address_str_ar: customerAddressStreetHe
    });


 
    let model = {
        customer_name_en: this.addCustomer.value.customer_name_en,
        customer_name_ar: this.addCustomer.value.customer_name_ar,
        customer_name_he: this.addCustomer.value.customer_name_he,
        customer_address_str: this.addCustomer.value.customer_address_str,
        customer_address_str_ar: this.addCustomer.value.customer_address_str_ar,
        customer_address_str_en: this.addCustomer.value.customer_address_str_en,

        customer_address_city: this.addCustomer.value.customer_address_city,
        customer_address_city_ar: this.addCustomer.value.customer_address_city_ar,
        customer_address_city_en: this.addCustomer.value.customer_address_city_en,
        customer_address_zip_code: this.addCustomer.value.customer_address_zip_code,
        customer_Building_number: this.addCustomer.value.customer_Building_number,
        customer_phone: this.addCustomer.value.customer_phone !== '' ? '+972' + this.addCustomer.value.customer_phone : '',
        customer_vat_number: this.addCustomer.value.customer_vat_number,
        customer_vat_tax: this.addCustomer.value.customer_vat_tax,
        id_number: this.addCustomer.value.id_number,
        customer_type: Number(this.addCustomer.value.customer_type),
        merchant_id: Number(this.merchantId),
        created_by: Number(this.userRegistId),
    };

 
    this.spinner.show();
    this.service.Post(AddCustomerEndPoient.POST, model).subscribe((res) => {
        if (res.status === 200) {
            Swal.fire({
                icon: 'success',
                title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
            });
            this.modalService.dismissAll();
             this.getAllCustomerType();
            this.addCustomer.reset({
                customer_name_en: '',
                customer_name_ar: '',
                customer_name_he: '',
                customer_address_str: '',
                customer_address_str_ar: '',
                customer_address_str_en: '',
                customer_address_city: '',
                customer_address_city_ar: '',
                customer_address_city_en: '',
                customer_address_zip_code: '',
                customer_Building_number: '',
                customer_phone: '',
                customer_vat_number: null,
                customer_vat_tax: null,
                id_number: null,
                customer_type: null,
            });
        } else if (res.status === 400 || res.status === 403 || res.status === 500) {
            let errorMessage;
            switch (res.status) {
                case 400:
                case 403:
                case 500:
                    errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
                    break;
                default:
                    errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
            }
            Swal.fire({
                icon: 'error',
                title: errorMessage
            });
        }
        this.spinner.hide();
    });
}


getCustomerTypeName() {
  const customerTypeId = this.addCustomer.get('customer_type').value;
  const selectedType = this.customerTypeData.find(item => item.lookup_detail_id === customerTypeId);
  return selectedType ? (this.curentLang === 'en' ? selectedType.lookupDetailEnName : 
                         (this.curentLang === 'ar' ? selectedType.lookupDetailArName : selectedType.lookupDetailHeName)) : '';
}

  isNumber(event: KeyboardEvent) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
    } else {
      return true;
    }
  }

  checkSequenceExists(companyId: number): void {
    const docType = '0'; // نوع المستند ثابت
     
    this.spinner.show();
    this.service.checkSequenceExists(companyId, docType).subscribe(res => {
      const sequenceExists = res;
  
      if (!sequenceExists) {
        Swal.fire({
          title: this.curentLang === 'en' ? 'Enter the starting number for the document sequence:' : this.curentLang === 'ar' ? 'أدخل الرقم الابتدائي لتسلسل المستند:' : 'הזן את המספר ההתחלתי של רצף המסמכים:',
          input: 'number',
          inputAttributes: {
            min: '1',
            step: '1'
          },
          inputValue: 1, // تعيين القيمة الافتراضية إلى 1
          showCancelButton: false,
          confirmButtonText: this.curentLang === 'en' ? 'Submit' : this.curentLang === 'ar' ? 'إرسال' : 'שלח',
          allowOutsideClick: false,
          allowEscapeKey: false,
          preConfirm: (value) => {
            if (!value || value < 1) {
              Swal.showValidationMessage(
                this.curentLang === 'en' ? 'Please enter a valid number greater than 0' : this.curentLang === 'ar' ? 'يرجى إدخال رقم صالح أكبر من 0' : 'אנא הזן מספר חוקי גדול מ-0'
              );
            }
          }
        }).then(result => {
          if (result.value) {
            this.createSequence(companyId, parseInt(result.value, 10));
          }
        });
      }
  
      this.spinner.hide();
    }, error => {
       this.spinner.hide();
    });
  }

  createSequence(companyId: number, startWith: number): void {
    this.spinner.show();
    const docType = '0'; // نوع المستند ثابت
  
    this.service.createSequence(companyId, docType, startWith).subscribe(res => {
      Swal.fire({
        icon: 'success',
        title: this.curentLang === 'en' ? 'Sequence created successfully' : this.curentLang === 'ar' ? 'تم إنشاء التسلسل بنجاح' : 'רצף נוצר בהצלחה'
      });
    }, error => {
     });
    this.spinner.hide();
  }

  onDateChange(event: any) {
    const selectedDate: Date = event;
    const formattedDate = selectedDate.toISOString().slice(0, 10).replace(/-/g, "");
 }

  
  availableLanguages = [
    // { code: "en", name: "English" },
    { code: "ar", name: "Arabic" },
    { code: "he", name: "Hebrew" }
];

// تعيين curentLang كلغة افتراضية
  
  invoiceform = this.builder.group({
    invoice_type : this.builder.control('0', Validators.required),
    customer_id: this.builder.control('', Validators.required),
    company_id: this.builder.control(null, Validators.required),
    vat_list_id: this.builder.control(null, Validators.required),
    vat_percentage: this.builder.control({ value: 0, disabled: true }),
    invoice_date: this.builder.control(this.datePipe.transform(new Date(), 'yyyy-MM-dd'), Validators.required),
    invoice_note: this.builder.control('', Validators.maxLength(255)),
    document_language: this.builder.control('he'),
    amount_before_discount : this.builder.control({ value: 0, disabled: true }),
    all_discount: this.builder.control({ value: 0, disabled: true }),
    payment_amount: this.builder.control({ value: 0, disabled: true }),
    all_vat_amount: this.builder.control({ value: 0, disabled: true }),
    payment_amount_including_vat: this.builder.control({ value: 0, disabled: true }),
    confirm: this.builder.control({ value: 0, disabled: true }),
    merchant_id : Number(this.merchantId),
    allocation_number: 0,
    created_by: Number(this.userRegistId),
    customer_name: this.builder.control('', Validators.required),
    customer_id_number : this.builder.control('' ),
    customer_vat_number : this.builder.control('' ),
    lines: this.builder.array([] , Validators.required),

  });
 
  confirmInvoice() {
    this.spinner.show();   
  
    if (this.invoiceform.valid) {
        this.spinner.show();
        return this.service.SaveInvoice(this.invoiceform.getRawValue()).pipe(
          concatMap((res: any) => {
            let result: any = res;
            this.invoiceId = result?.data?.invoice_id;
            this.uuId = result?.data?.invoice_uuid;

            if (result.status === 200) {
                // Return an observable here
                return of(null);
            }
             else if (result.status === 400 || result.status === 403 || result.status === 500) {
                this.spinner.hide();
                let errorMessage;
                switch (result.status) {
                    case 400:
                    case 403:
                    case 500:
                        errorMessage = this.curentLang === 'ar' ? result.messageAr : (this.curentLang === 'en' ? result.messageEn : result.messageHe);
                        break;
                    default:
                        errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
                }
                Swal.fire({
                    icon: 'error',
                    title: errorMessage
                });
                // Return an observable here
                return throwError(errorMessage);
            }
            // Return an observable here
            return of(null);
          }),
          catchError(error => {
            this.spinner.hide();
             return throwError(error);
          }),
          concatMap(() => this.service.confirmInvoice(this.invoiceId))
        ).subscribe((res) => {
            if (res.status === 200) {
              this.router.navigate([`/dashboard/offer-price`]);
                const Toast = Swal.mixin({
                  toast: true,
                  position: 'top',
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                  color: 'green',
                  backdrop:true,
                  didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                  }
                })
                
                Toast.fire({
                  icon: 'success',
                  title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)                })

               

                this.modalService.dismissAll();
            } else if (res.status === 400 || res.status === 403 || res.status === 500) {
                let errorMessage;
                switch (res.status) {
                    case 400:
                    case 403:
                    case 500:
                        errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
                        this.router.navigate(['/dashboard/offer-price']);

                        break;
                        
                    default:
                        errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
                }
                this.toastr.error(errorMessage)
            }
            this.spinner.hide();
        });
    } else {
        Swal.fire({
            icon: 'warning',
            title: this.curentLang === 'ar' ? 'يرجى ملء جميع الحقول' : (this.curentLang === 'en' ? 'Please fill all fields' : (this.curentLang === 'he' ? 'נא למלא כל השדות' : ''))
        });
        this.spinner.hide();
        return EMPTY;
    }
    
  }
  


  SaveInvoice() {
     
    if (this.invoiceform.valid) {
    // تنفيذ الدالة SaveInvoice() والاشتراك في القيمة المُرجعة
    this.saveInvoiceButtonClicked = true; // تحديد أن الزر "حفظ الفاتورة" قد تم النقر عليه

    this.spinner.show();
    this.service.SaveInvoice(this.invoiceform.getRawValue()).subscribe(
        (res: any) => {
            let result: any = res;
            this.invoiceId = result?.data?.invoice_id;
            this.uuId = result?.data?.invoice_uuid;
             
            // تحقق مما إذا كانت الاستجابة ناجحة وقم بالمعالجة بشكل مناسب
            if (result.status === 200) {
                this.spinner.hide();
                this.router.navigate(['/dashboard/offer-price']);
                const Toast = Swal.mixin({
                  toast: true,
                  position: 'top',
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                  color: 'green',
                  backdrop:true,
                   
                  didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                  }
                })
                
                Toast.fire({
                  icon: 'success',
                  title: this.curentLang === 'ar' ? result.messageAr : (this.curentLang === 'en' ? result.messageEn : result.messageHe)
                }).then(() => {
                  
                    this.spinner.hide();
                    this.invoiceform.reset();
                    
                });
            } else if (result.status === 400 || result.status === 403 || result.status === 500) {
                this.spinner.hide();
                let errorMessage;
                switch (result.status) {
                    case 400:
                    case 403:
                    case 500:
                        errorMessage = this.curentLang === 'ar' ? result.messageAr : (this.curentLang === 'en' ? result.messageEn : result.messageHe);
                        break;
                    default:
                        errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
                }
                 
                this.toastr.error(errorMessage)
            }
        },
        error => {
            this.spinner.hide();
         }
    );
  }
  else{
    Swal.fire({
      icon: 'warning',
      title: this.curentLang === 'ar' ? 'يرجى ملء جميع الحقول' : (this.curentLang === 'en' ? 'Please fill all fields' : (this.curentLang === 'he' ? 'נא למלא כל השדות' : ''))
  });
  this.spinner.hide();
  return EMPTY;
  }

  this.spinner.hide()
}

 
 
  addnewproduct() {
  
    this.invoicedetail = this.invoiceform.get("lines") as FormArray;
  
    let customercode = this.invoiceform.get("customer_id")?.value;
    let vatListId = this.invoiceform.get("vat_list_id")?.value;
    let invoiceTypeCode = this.invoiceform.get("invoice_type")?.value;
    let invoiceDate = this.invoiceform.get("invoice_date")?.value;
    let companyid = this.invoiceform.get("company_id")?.value;
    
  
    if (
      (customercode != null && customercode != '') &&
      (vatListId != null && vatListId != '') &&
      (invoiceTypeCode != null && invoiceTypeCode != '') &&
      (invoiceDate != null && invoiceDate != '') &&
      (companyid != null && companyid != '') ||
      this.isedit
    ) {
      this.invoicedetail.push(this.Generaterow());
      
    } else {
      
      Swal.fire({
        icon: 'warning',
        title: this.curentLang === 'ar' ? 'يرجى ملء جميع الحقول' : (this.curentLang === 'en' ? 'Please fill all fields' : (this.curentLang === 'he' ? 'נא למלא כל השדות' : ''))
      });
    }
  };

  get invproducts() {
    return this.invoiceform.get("lines") as FormArray;
  };

  Generaterow() {
    const newIndex = this.invoicedetail.length + 1;  
    
     
    this.stopVatPercentageSubscription();


    return this.builder.group({
        index: this.builder.control(newIndex),
        product_id: this.builder.control('', Validators.required),
        category: this.builder.control('', Validators.required),
        measure_unit_description: this.builder.control('', Validators.required),
        measurement_unit_id: this.builder.control('', Validators.required),
        description: this.builder.control('', Validators.required),
        catalog_id: this.builder.control('', Validators.required),
        item_name: this.builder.control('', Validators.required),
        quantity: this.builder.control(1),
        price_per_unit: this.builder.control(0),
        discount: this.builder.control(0),
        sub_total: this.builder.control({ value: 0, disabled: true }),
        vat_rate: this.builder.control({ value: this.VatPercentage, disabled: true }),
        vat_amount: this.builder.control({ value: 0, disabled: true }),
        total_amount: this.builder.control(0),
    });
};

  stopVatPercentageSubscription()
{
    this.invoiceform.get('vat_list_id')?.disable();
    this.invoiceform.get('invoice_date')?.disable();
};

resumeVatPercentageSubscription()
{
  this.invoiceform.get('vat_list_id')?.enable();
  this.invoiceform.get('invoice_date')?.enable();
};

  GetCustomers()
   {
    this.spinner.show();
    this.service.getAllActiveCustomersBasedOnMerchantId().subscribe(res => {
      this.mastercustomer = res;
      this.spinner.hide();
    })
  };
  getCompanyInfoById() {
    this.spinner.show();
    const companyId = this.invoiceform.get("company_id")?.value;
    if (companyId != null && companyId != '') {
      this.service.getCompanyInfoById(this?.companyId).subscribe({
        next: res => {
          this.oneCompanyData = res;
          this.spinner.hide();
        },
        error: error => {
          this.spinner.hide();
        }
      });
    } else {
      this.spinner.hide();
    }
  };

  getIdd(id: number) {
    this.id = id;
  };
  getCustomerById() {
    const customerId = this.invoiceform.get("customer_id")?.value;
    if (customerId != null && customerId !== '') {
      this.spinner.show();
      this.service.getCustomerById(Number(customerId)).subscribe({
        next: res => {
          this.oneCustomerData = res;
  
          // تحديث النموذج ببيانات العميل المستلمة
          this.invoiceform.patchValue({
            customer_name: this.oneCustomerData?.['customer_name_' + this.curentLang].substring(0, 100),
            customer_id_number: this.oneCustomerData?.id_number,
            customer_vat_number: this.oneCustomerData?.customer_vat_number || 0 ,
          });
  
          this.spinner.hide();
        },
        error: error => {
          this.spinner.hide();
        }
      });
    }
  }
  
  
  

  GetCompany() 
{
    this.service.GetCompany().subscribe(res => {
      this.mastercompany = res;
    })
  };

  GetProducts() 
  {
    this.service.getAllActiveProductsBasedOnMerchantId().subscribe(res => {
      this.masterproduct = res;

    })
   };

  GetInvoiceTypeList()
  {
    this.spinner.show();

    this.service.GetInvoiceTypeList().subscribe(res => {
      this.masterInvoiceTypeList = res;
      this.spinner.hide();
    })
  };

  customerchange()
 {
    let customer_id = this.invoiceform.get("customer_id")?.value;
    this.service.GetCustomerbyId(customer_id).subscribe(res => {
      let custdata: any;
      custdata = res;

    });
  };

  companychange()
  {
    let company_id = this.invoiceform.get("company_id")?.value;
    this.service.GetCompanybyId(company_id).subscribe(res => {
      let custdata: any;
      custdata = res;

    });
  };
  getCompanyId(id: any) {
    this.spinner.show();
    this.companyId =  id;
    this.spinner.hide();    
  };

  getVatPercentageByVatListIdAndDate()
  {
    const vatListId = this.invoiceform.get("vat_list_id")?.value;
    const invoiceDate = this.invoiceform.get("invoice_date")?.value;
  
    if (vatListId && invoiceDate) {
      this.spinner.show();
      this.service.getVatPercentageByVatListIdAndDate(vatListId, invoiceDate).subscribe({
        next: res => {
          this.vatPersentageData = res;
          if (this.vatPersentageData && this.vatPersentageData.length > 0) {
            this.VatPercentage = this.vatPersentageData[0].vat_percentage;
            this.invoiceform.patchValue({
              vat_percentage: this.VatPercentage // تحديث القيمة في النموذج
            });
            this.summarycalculation(); // إعادة حساب القيم الإجمالية للفاتورة
          } else {
            // إعادة تهيئة قيمة vat_percentage إلى قيمتها الافتراضية
            this.invoiceform.patchValue({
              vat_percentage: 0
            });
          }
        },
        error: error => {
           // إعادة تهيئة قيمة vat_percentage إلى قيمتها الافتراضية
          this.invoiceform.patchValue({
            vat_percentage: 0
          });
        },
        complete: () => {
          this.spinner.hide(); // إخفاء المحمل بعد الحصول على البيانات بنجاح أو بعد حدوث خطأ
        }
      });
    } else {
      // إعادة تهيئة قيمة vat_percentage إلى قيمتها الافتراضية
      this.invoiceform.patchValue({
        vat_percentage: 0
      });
      this.spinner.hide(); // إخفاء المحمل إذا لم يتم تحديد كلا من vatListId و invoiceDate
    }

    
  };
  
  filter(event:any)
  {
    let x = new Date(event.target.value);    
    this.selectedMonth = x.getMonth() + 1; // Month is 0-based, so add 1
    this.selectedYear = x.getFullYear();
      
    this.checkClosedMonth();
    
  };

  checkClosedMonth() {
    this.spinner.show();
    this.service.checkClosedMonth(this.companyDefault, this.selectedMonth, this.selectedYear).subscribe({
      next: res => {
        this.spinner.hide();
        if (res.status == 200) {  
          this.toastr.warning(
            this.curentLang === 'en' 
              ? res.messageEn
              : this.curentLang === 'ar' 
              ? res.messageAr
              : res.messageHe,
            '',
            { timeOut: 7000 } // زيادة مدة العرض إلى 5 ثوانٍ
          );
        } else {
          this.toastr.info(
            this.curentLang === 'en' 
              ? 'The selected month and year are open.'
              : this.curentLang === 'ar' 
              ? 'الشهر والسنة المختارة مفتوحة.'
              : 'החודש והשנה שנבחרו פתוחים.',
            '',
            { timeOut: 7000 } // زيادة مدة العرض إلى 5 ثوانٍ
          );
        }
      },
      error: error => {
        this.spinner.hide();
      }
    });
  }

  getId(id: number) {
    this.invoiceId = id;
  };
  vatTypechange() {
    this.spinner.show();

    this.service.GetVatTypeList().subscribe(res => {
      this.vatTypeList = res;
    });

    this.spinner.hide();
  };

  // productchange(index: any) {
  //   this.invoicedetail = this.invoiceform.get("lines") as FormArray;
  //   this.invoiceproduct = this.invoicedetail.at(index) as FormGroup;
  //   let product_id = this.invoiceproduct.get("product_id")?.value;
  //   this.service.GetProductbycode(product_id).subscribe(res => {
  //     let proddata: any;
  //     proddata = res;

  //     if(proddata?.product_code === '' || null ||
  //        proddata?.categories_id ===   null 
  //       || proddata?.measurement_unit_id ===   null)
  //       {
  //         Swal.fire({
  //           icon: 'warning',
  //           title: this.curentLang === 'ar' ? ' برجاء استكمال معلومات المنتج والمحاولة مرة اخري' : (this.curentLang === 'en' ? 'Please complete the product information and try again' : (this.curentLang === 'he' ? 'אנא מלא את פרטי המוצר ונסה שוב' : ''))
  //         }); 

       
  //       return
         
  //       }

  //     if (proddata != null) {
  //       this.invoiceproduct.get("productName")?.setValue(proddata.name);
  //       this.invoiceproduct.get("price_per_unit")?.setValue(proddata.price);
  //       this.invoiceproduct.get("category")?.setValue(proddata.categories.categories_code,);
  //       this.invoiceproduct.get("measure_unit_description")?.setValue(proddata.measurementUnits['measurement_unit_name_' + this.curentLang].substring(0, 20),);
  //       this.invoiceproduct.get("measurement_unit_id")?.setValue(proddata.measurementUnits.measurement_unit_id);
  //       this.invoiceproduct.get("description")?.setValue(proddata['des_' + this.curentLang].substring(0, 30));
  //       this.invoiceproduct.get("catalog_id")?.setValue(proddata.product_code.substring(0, 13));
  //       this.invoiceproduct.get("item_name")?.setValue(proddata['product_name_' + this.curentLang]);
  //       this.Itemcalculation(index);
  //     }
  //   });
  // };
  addedProductData() {
    const product_name_he = String(this.addProduct.value.product_name_he)?.trim() || '';
    const product_code = String(this.addProduct.value.product_code)?.trim() || '';
    const price = Number(this.addProduct.value.price) || 0;
    const des_he = String(this.addProduct.value.des_he)?.trim() || '';
    const measurement_unit_id = Number(this.addProduct.value.measurement_unit_id) || 0;
    const categories_id = Number(this.addProduct.value.categories_id) || 0;

    if (
        product_name_he === '' ||
        product_code === '' ||
      //  price === 0 ||
        des_he === '' ||
        measurement_unit_id === 0 ||
        categories_id === 0
    ) {
        Swal.fire({
            icon: 'warning',
            title: this.curentLang === 'ar' ? 'يرجى ملء الحقول المطلوبة وعدم تركها فارغة أو تحتوي على مسافات فقط' : 
                   (this.curentLang === 'en' ? 'Please fill out the required fields and do not leave them empty or containing only spaces' : 
                   (this.curentLang === 'he' ? 'אנא מלא את השדות הנדרשים ואל תשאיר אותם ריקים או המכילים רק רווחים' : ''))
        });

        this.addProduct.markAllAsTouched();
        this.validate = !this.validate;
        return;
    }

    // تعيين قيمة product_name_he إلى الحقول الأخرى
    const productNameHe = this.addProduct.value.product_name_he;
    const descriptionNameHe = this.addProduct.value.des_he;
    this.addProduct.patchValue({
        product_name_ar: productNameHe,
        product_name_en: productNameHe,
        des_ar: descriptionNameHe,
        des_en: descriptionNameHe,
    });

    let model = {
        product_name_en: this.addProduct.value.product_name_en,
        product_name_ar: this.addProduct.value.product_name_ar,
        product_name_he: this.addProduct.value.product_name_he,
        product_code: this.addProduct.value.product_code,
        price: price,
        des_ar: this.addProduct.value.des_ar,
        des_en: this.addProduct.value.des_en,
        des_he: this.addProduct.value.des_he,
        measurement_unit_id: measurement_unit_id,
        categories_id: categories_id,
        merchant_id: Number(this.merchantId),
        created_by: Number(this.userRegistId),
    };


    this.spinner.show();
    this.service.Post(AddProductEndPoient.POST, model).subscribe((res) => {
        if (res.status === 200) {
            Swal.fire({
                icon: 'success',
                title: this.curentLang === 'ar' ? res.messageAr : 
                       (this.curentLang === 'en' ? res.messageEn : res.messageHe)
            });
            this.modalService.dismissAll();
         
            this.getAllCustomerType();
            this.GetProducts();
         

            this.addProduct.reset();
        } else if (res.status === 400 || res.status === 403 || res.status === 500) {
            let errorMessage;
            switch (res.status) {
                case 400:
                    errorMessage = this.curentLang === 'ar' ? res.messageAr : 
                                   (this.curentLang === 'en' ? res.messageEn : res.messageHe);
                    break;
                case 403:
                    errorMessage = this.curentLang === 'ar' ? res.messageAr : 
                                   (this.curentLang === 'en' ? res.messageEn : res.messageHe);
                    break;
                case 500:
                    errorMessage = this.curentLang === 'ar' ? res.messageAr : 
                                   (this.curentLang === 'en' ? res.messageEn : res.messageHe);
                    break;
                default:
                    errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : 
                                   (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
            }
            Swal.fire({
                icon: 'error',
                title: errorMessage
            });
        }
        this.spinner.hide();
    });
};

  openXl2(content2:any) {
    this.modalService.open(content2, { size: 'xl' , centered:true });
  };


  productchange(index: any) {
    this.invoicedetail = this.invoiceform.get("lines") as FormArray;
    this.invoiceproduct = this.invoicedetail.at(index) as FormGroup;

    // استماع للتغيرات على Total Amount
    this.invoiceproduct.get('total_amount').valueChanges.subscribe(newTotalAmount => {
        this.calculateReverse(index, newTotalAmount);
    });

    let product_id = this.invoiceproduct.get("product_id")?.value;

    this.service.GetProductbycode(product_id).subscribe(res => {
        let proddata: any;
        proddata = res;

        // التحقق من إذا كانت بيانات المنتج غير مكتملة
        if (proddata?.product_code === '' || proddata?.product_code === null ||
            proddata?.categories_id === null || proddata?.measurement_unit_id === null) {
            
            // عرض الرسالة التحذيرية وإدخال للمستخدم لإضافة الكود
            Swal.fire({
              icon: 'warning',
              title: this.curentLang === 'ar' ? ' برجاء استكمال معلومات المنتج والمحاولة مرة اخري' : 
                     (this.curentLang === 'en' ? 'Please complete the product information and try again' : 
                     (this.curentLang === 'he' ? 'אנא מלא את פרטי המוצר ונסה שוב' : '')),
              input: 'text', // إدخال نص للمستخدم
              inputPlaceholder: this.curentLang === 'ar' ? 'أدخل كود المنتج' : 
                                 (this.curentLang === 'en' ? 'Enter product code' : 
                                 (this.curentLang === 'he' ? 'הזן קוד מוצר' : '')),
              showCancelButton: true,
              confirmButtonText: this.curentLang === 'ar' ? 'إرسال' : 
                                 (this.curentLang === 'en' ? 'Submit' : 
                                 (this.curentLang === 'he' ? 'שלח' : '')),
              preConfirm: (value) => {
                if (!value || value.trim() === '') {
                    Swal.showValidationMessage(
                        this.curentLang === 'ar' ? 'لا يمكن إدخال قيمة فارغة أو مسافة فقط' : 
                        (this.curentLang === 'en' ? 'Empty or whitespace-only values are not allowed' : 
                        (this.curentLang === 'he' ? 'אין להזין ערך ריק או הכולל רק רווחים' : ''))
                    );
                    return false;
                }
                return value.trim();
              }
            }).then(result => {
                if (result.isConfirmed && result.value) {
                    let newProductCode = result.value;
                    this.invoiceproduct.get("catalog_id")?.setValue(newProductCode);

                    // إرسال الكود الجديد إلى الخادم
                    const model = {
                      product_code: newProductCode,
                      updated_by: this.userRegistId
                    };

                    this.spinner.show();
                    this.service.updateProductsById(product_id, model).subscribe((res) => {
                      

                        // جلب البيانات المحدثة بعد التعديل
                        this.service.GetProductbycode(product_id).subscribe(updatedProdData => {
                            this.updateFormWithProductData(updatedProdData, index);
                        });
                    });
                    this.spinner.hide();
                }
            });

            return;
        }

        if (proddata != null) {
            this.updateFormWithProductData(proddata, index);
        }
    });
}

// دالة لتحديث حقول النموذج بقيم المنتج المحدثة
updateFormWithProductData(proddata: any, index: number) {
    this.invoiceproduct = this.invoicedetail.at(index) as FormGroup;
    this.invoiceproduct.get("productName")?.setValue(proddata.name);
    this.invoiceproduct.get("price_per_unit")?.setValue(proddata.price);
    this.invoiceproduct.get("category")?.setValue(proddata.categories.categories_code);
    this.invoiceproduct.get("measure_unit_description")?.setValue(proddata.measurementUnits['measurement_unit_name_' + this.curentLang].substring(0, 20));
    this.invoiceproduct.get("measurement_unit_id")?.setValue(proddata.measurementUnits.measurement_unit_id);
    this.invoiceproduct.get("description")?.setValue(proddata['des_' + this.curentLang].substring(0, 300));
    this.invoiceproduct.get("catalog_id")?.setValue(proddata.product_code.substring(0, 13));
    this.invoiceproduct.get("item_name")?.setValue(proddata['product_name_' + this.curentLang]);
    this.Itemcalculation(index);
}

//new
onTotalAmountChange(index: number) {
  this.invoicedetail = this.invoiceform.get("lines") as FormArray;
  this.invoiceproduct = this.invoicedetail.at(index) as FormGroup;

  const newTotalAmount = parseFloat(this.invoiceproduct.get("total_amount")?.value);
  if (!isNaN(newTotalAmount)) {
      this.calculateReverse(index, newTotalAmount);
  }
}


/// new
calculateReverse(index: any, newTotalAmount: number) {
  this.invoicedetail = this.invoiceform.get("lines") as FormArray;
  this.invoiceproduct = this.invoicedetail.at(index) as FormGroup;

  let quantity = this.invoiceproduct.get("quantity")?.value || 1; // التأكد من أن quantity ليس صفرًا
  let vat_rate = this.invoiceproduct.get("vat_rate")?.value || 0;
  let discount = this.invoiceproduct.get("discount")?.value || 0;

  // حساب Sub Total قبل تطبيق الضريبة
  let sub_total_before_vat = newTotalAmount / (1 + (vat_rate / 100));
  
  // حساب الخصم الكامل (يتم تطبيق الخصم على السعر الإجمالي للمنتجات)
  let total_without_discount = sub_total_before_vat + discount;

  // حساب السعر لكل وحدة
  let price_per_unit = total_without_discount / quantity;

  // حساب مبلغ الضريبة
  let vat_amount = parseFloat((sub_total_before_vat * (vat_rate / 100)).toFixed(2));

  // تعيين القيم المحسوبة إلى النموذج
  this.invoiceproduct.get("sub_total")?.setValue(sub_total_before_vat.toFixed(2));
  this.invoiceproduct.get("price_per_unit")?.setValue(price_per_unit.toFixed(2));
  this.invoiceproduct.get("vat_amount")?.setValue(vat_amount.toFixed(2));
  
  // تحديث القيم الإجمالية للفاتورة
  this.summarycalculation();
}



  Itemcalculation(index: any) {
    this.invoicedetail = this.invoiceform.get("lines") as FormArray;
    this.invoiceproduct = this.invoicedetail.at(index) as FormGroup;
    
    let quantity = this.invoiceproduct.get("quantity")?.value;
    let price = this.invoiceproduct.get("price_per_unit")?.value;
    let discount = this.invoiceproduct.get("discount")?.value;
    let vat_rate = this.invoiceproduct.get("vat_rate")?.value;
  
    let sub_total = (quantity * price) - discount; // الحساب الفرعي للعنصر بعد الخصم
    let vat_amount = parseFloat((sub_total * (vat_rate / 100)).toFixed(2)); // حساب مبلغ الضريبة المضافة
    let total_amount = parseFloat((sub_total + vat_amount).toFixed(2)); // حساب السعر النهائي بإضافة مبلغ الضريبة المضافة إلى الحساب الفرعي
    
    this.invoiceproduct.get("sub_total")?.setValue(sub_total); // تعيين قيمة الحساب الفرعي
    this.invoiceproduct.get("vat_amount")?.setValue(vat_amount); // تعيين قيمة مبلغ الضريبة المضافة
    this.invoiceproduct.get("total_amount")?.setValue(total_amount); // تعيين السعر النهائي للعنصر
    
    this.summarycalculation(); // إعادة حساب القيم الإجمالية للفاتورة
  };
  
  Removeproduct(index: any)
  {
      this.invproducts.removeAt(index);
      this.summarycalculation();
      if( this.invproducts.length == 0){
        this.resumeVatPercentageSubscription();
      }
    
  };

  summarycalculation() {
    let array = this.invoiceform.getRawValue().lines;
    
    let amount_before_discount = 0;
    let all_discount = 0;
    let all_vat_amount = 0;
  
    array.forEach((x: any) => {
      amount_before_discount += parseFloat(x.price_per_unit) * parseFloat(x.quantity);
      all_discount += parseFloat(x.discount);
      all_vat_amount += parseFloat(x.vat_amount);
    });
  
    let payment_amount = amount_before_discount - all_discount; // المبلغ المطلوب للدفع
    let payment_amount_including_vat = payment_amount + all_vat_amount; // المبلغ المطلوب للدفع بما فيه ضريبة القيمة المضافة
  
    this.invoiceform.get("amount_before_discount")?.setValue(parseFloat(amount_before_discount.toFixed(2)));
    this.invoiceform.get("all_discount")?.setValue(parseFloat(all_discount.toFixed(2)));
    this.invoiceform.get("payment_amount")?.setValue(parseFloat(payment_amount.toFixed(2)));
    this.invoiceform.get("all_vat_amount")?.setValue(parseFloat(all_vat_amount.toFixed(2)));
    this.invoiceform.get("payment_amount_including_vat")?.setValue(parseFloat(payment_amount_including_vat.toFixed(2)));
}
}
