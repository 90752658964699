<ng-container *ngIf="!isInsideModal">

<app-breadcrumb [title]="curentLang === 'en' ? 'Companies Info' : (curentLang === 'ar' ? ' معلومات الشركات  ' : '   מידע על חברות  ')" 
[items]="curentLang === 'en' ? ['Dashboard'] : (curentLang === 'ar' ? ['لوحة التحكم'] : ['    הגדרות'])" 
[active_item]="curentLang === 'en' ? 'Companies Info' : (curentLang === 'ar' ? ' معلومات الشركات  ' : '   מידע על חברות ')">
</app-breadcrumb>

</ng-container>

<!--company info -->

<div class="container-fluid basic_table">
    <div class="row">
<div class="col-sm-12">
    <div class="card">
      <div class="card-header d-flex justify-content-between">
        <h5 style="color: grey;"> 
            {{ curentLang === 'en' ? 'Companies Info' : (curentLang === 'ar' ? '  معلومات الشركات ' : '  מידע על חברות ')}}
        </h5>
           
       <div>
        <button [hidden]="user_type == 200 || user_type == 201" class="btn m-1" style=" background-color: #141414; color: #fff;" (click)="openXl(content)">
          <i class="fa fa-plus-circle mx-1"></i>
        {{ curentLang === 'en' ? 'Add Company' : (curentLang === 'ar' ? '  اضافة شركة ' : '  הוספת חברה ')}}
        
      </button>

      <button [hidden]="user_type == 100 || user_type == 101" class="btn m-1" style=" background-color: #141414; color: #fff;" (click)="openXl2(contentAdmin)">
        <i class="fa fa-plus-circle mx-1"></i>
      {{ curentLang === 'en' ? 'Add Company' : (curentLang === 'ar' ? '  اضافة شركة  ' : '  הוספת חברה ')}}
      
    </button>

    <button [hidden]="user_type == 100 || user_type == 101" class="btn m-1" style=" background-color: #141414; color: #fff;" (click)="exportAllToExcel()">
      <i class="fa fa-file-excel-o mx-1"></i>
    {{ curentLang === 'en' ? 'Export Excel  ' : (curentLang === 'ar' ? '  تصدير اكسيل    ' : '   ייצוא אקסל    ')}}
    
  </button>

      <button class="btn m-1 mx-1 btn-outline-warning"  (click)="getAllCompaniesInfo()">
        <i class="fa fa-refresh mx-1"></i> {{ curentLang === 'en' ? 'Refresh' : (curentLang === 'ar' ? '  تحديث   ' : '  רענן    ')}}</button>
       </div>
     
      </div>

      <div class="row">
      

        <div class="col-sm-5">
          <div class="form-group search">
            <label for="search" style="color: grey;">
              {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}
            </label>
            <div class="input-group">
              <input class="form-control form-control-search" type="text" name="search"
                     [ngModelOptions]="{standalone: true}" id="search" [(ngModel)]="term" style="color: black;"
                     placeholder=" {{curentLang === 'en' ? 'You can search by name or tax number ' : (curentLang === 'ar' ? 'يمكنك البحث عن طريق الأسم أو الرقم الضريبي' : 'ניתן לחפש לפי שם או מספר מס')}}"

                     >
              <!-- <div class="input-group-append">
                <button class="btn" style="background-color: #141414; color: #fff;" type="button"
                        (click)="getAllCompaniesInfo(limit, offset, term)">
                  <i class="fa fa-search mx-1"></i>
                  {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}
                </button>
                <button class="btn  btn-outline-secondary" type="button" (click)="term='';getAllCompaniesInfo(limit, offset, term)">
                  <i class="fa fa-times"></i>
                </button>
              </div> -->
            </div>
          </div>
        </div>
    </div>

      <div class="card-block row">
        <div class="col-sm-12 col-lg-12 col-xl-12">
          <div class="table-responsive">
            <table class="table table-light text-center table-hover  " >
              <thead>
                <tr>
                  <th scope="col">{{ curentLang === 'en' ? 'index' : (curentLang === 'ar' ? '  م' : '  מ ') }}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Company Name' : (curentLang === 'ar' ? '  أسم الشركة  ' : '    שם החברה  ')}} </th>
                   <th scope="col"> {{ curentLang === 'en' ? 'Tax registration number' : (curentLang === 'ar' ? '   رقم السجل الضريبي  ' : 'מס עוסק מורשה    ')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Commercial register' : (curentLang === 'ar' ? ' رقم السجل التجاري  ' : '     מרשם מסחרי ')}}</th>

                  <th scope="col"> {{ curentLang === 'en' ? 'Created Date' : (curentLang === 'ar' ? '  تاريخ التسجيل' : '    תאריך הרשמה')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Connect with the IRS' : (curentLang === 'ar' ? 'ربط مع مصلحة الضرائب' : 'התחבר למס הכנסה')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? '  Expiry Date ' : (curentLang === 'ar' ? 'تاريخ انتهاء الصلاحية' : ' תאריך תפוגה    ')}}</th>
                  <th scope="col" *ngIf="user_type == 200 || user_type == 201"> {{ curentLang === 'en' ? 'Company Documents  ' : (curentLang === 'ar' ? 'مستندات الشركة  ' : ' מסמכי החברה   ')}}</th> 

                  <th scope="col"> {{ curentLang === 'en' ? 'Additional Information ' : (curentLang === 'ar' ? ' معلومات اضافية' : 'מידע נוסף')}}</th>

                  

                  <th scope="col"> {{ curentLang === 'en' ? 'Add Accounting Office' : (curentLang === 'ar' ? 'اضافة مكتب محاسبة' : 'הוסף משרד רואי חשבון')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'sales report' : (curentLang === 'ar' ? 'اصدار تقرير مبيعات ' : 'הוצאת דו"ח מכירה')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Display signature on invoice  ' : (curentLang === 'ar' ? '    عرض التوقيع في الفاتورة ' : 'הצג את החתימה בחשבונית "   ')}}</th>
                  <th *ngIf="user_type == 200 || user_type == 201" scope="col"> {{ curentLang === 'en' ? 'Sequence  ' : (curentLang === 'ar' ? '  التسلسل   ' : '  רֶצֶף  ')}}</th>

                  <th scope="col"> {{ curentLang === 'en' ? 'View' : (curentLang === 'ar' ? '   عرض' : '  לצפות   ')}}</th>

                  <th scope="col"> {{ curentLang === 'en' ? 'Update  ' : (curentLang === 'ar' ? '   تعديل  ' : '  תיקון ')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Delete  ' : (curentLang === 'ar' ? '   حذف  ' : '    מחיקה ')}}</th>

                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let item of companyData | paginate: {itemsPerPage: limit, currentPage: currentPage, totalItems: total} |filter:term ; let i = index ">
                  <td scope="row">{{i+1}}</td>
                  <td> 
                    {{curentLang === 'en' ? item.company_name_en : (curentLang === 'ar' ? item.company_name_ar : item.company_name_he)}}
                  </td>

                   <td>{{item.tax_register}}</td>
                   <td>{{ item.commercial_register}}  </td>
                 
                   <td>{{ item?.createdDate | date: 'dd/MM/yyyy' }}</td>

                   <td>
                    <!-- <i class="fa fa-recycle"
                       [ngStyle]="{'color': isTokenExpired(item.refresh_token_expiration) ? 'black' : 'grey', 'font-size': '15px', 'cursor': isTokenExpired(item.refresh_token_expiration) ? 'pointer' : 'not-allowed'}"
                       (click)="handleClick(item.company_id, content4, item.refresh_token_expiration)"
                       [attr.disabled]="isTokenExpired(item.refresh_token_expiration) ? null : true">
                    </i> -->

                       <i class="fa fa-recycle"
                      style="color: #141414; font-size: 15px; cursor: pointer;"
                       (click)="handleClick(item.company_id, item.refresh_token_expiration);ConnectWithTaxGovernment()"
                      >
                    </i>
                  </td>
                  
                  <td>{{ item.refresh_token_expiration | date:'shortDate' }}</td>
                  <td *ngIf="user_type == 200 || user_type == 201">
                    <i class="fa fa-file-text-o" style="color: black;   cursor: pointer;"
                     routerLink="/dashboard/documents/{{item.company_uuid}}"></i>
                  </td>
                  <td>
                    <i class="fa fa-info-circle" style="color: rgb(195, 181, 22);  font-size: 15px; cursor: pointer;"
                    routerLink="/dashboard/more-info-company/{{item.company_id}}"></i>
                  </td>


                   <td>
                    <i class="fa fa-plus-circle" style="color: rgb(0, 0, 0);  font-size: 15px; cursor: pointer;"
                    (click)="getCompanyId(item.company_id,item.merchant_id);openXl3(content3)"></i>
                  </td>

                  <td>
                    <i class="fa fa-file-pdf-o" style="color: rgb(46, 63, 135);  font-size: 15px; cursor: pointer;"
                    (click)="getCompanyId(item.company_id,item.merchant_id);openXl5(content5)"></i>
                  </td>

                  <td>
                    <button  *ngIf="item.show_signature == true" class="btn" style="border: none;" 
                    (click)="changeShowSignatureCompany(item?.company_id, item.show_signature)">
                      <i class="fa solid fa-solid fa-toggle-on" style=" color: #213FA6;"></i>
                    </button> 

                    <button *ngIf="item.show_signature == false" class="btn" style="border: none;"
                     (click)="changeShowSignatureCompany(item?.company_id, item.show_signature)">
                      <i class="fa solid fa-solid fa-toggle-off" style=" color: red;"></i>
                    </button> 
                  </td>

                  <td *ngIf="user_type == 200 || user_type == 201">
                    <i class="fa fa-random" style="color: rgb(212, 85, 39);  font-size: 15px; cursor: pointer;"
                    routerLink="/dashboard/sequanse/{{item.company_id}}"></i>
                  </td>

                  <td>
                    <i class="fa fa-eye" style="color: grey;   cursor: pointer;"
                     routerLink="/dashboard/view-company/{{item.company_id}}"></i>
                  </td>

                   <td>
                     <i class="fa fa-edit" style=" cursor: pointer; color: green;"
                     routerLink="/dashboard/update-company/{{item.company_uuid}}">
                    </i>
                   </td>

                   <td >
                    <button  
                     class="btn text-danger" 
                    style="border: none;" 
                    (click)="getId(item?.company_id);openVerticallyCentered(dataDeleted)">
                      <i class="fa fa-trash-o" style=" cursor: pointer;"></i>
                      </button>
                     </td>

                </tr>
          
              </tbody>
            </table>
            <div class="pagination-container">
              <pagination-controls 
                (pageChange)="onPageChange($event)" 
                (totalItems)="total" 
                (currentPage)="currentPage">
              </pagination-controls>
              <input type="number" class="mt-1" [(ngModel)]="customLimit" (change)="onLimitChange()">
            </div>
          </div>

          <div class=" d-flex justify-content-between"> 
            <div></div>

           <div *ngIf="user_type == 100 || user_type == 101">
            <a routerLink="/dashboard/merchant-info">
                <button class="btn btn-danger m-2 " *ngIf="!isInsideModal">
                  {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                  <i class="fa fa-rotate-left mx-1"></i>
                </button>
              </a>
           </div>

           <div *ngIf="user_type == 200 || user_type == 201">
            <a routerLink="/dashboard/companies">
                <button class="btn btn-danger m-2 " >
                  {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                  <i class="fa fa-rotate-left mx-1"></i>
                </button>
              </a>
           </div>

          </div>

        </div>
      </div>
    </div>
  </div>
 
    </div>
</div>

<!--add company-->

<ng-template #content let-modal>
    <div class="modal-header d-flex justify-content-between">
        <h6 class="modal-title"style="color: grey;">
                {{ curentLang === 'en' ? 'Add Company ' : (curentLang === 'ar' ? ' اضافة شركة ' : '  הוספת חברה')}} 
        </h6>
        <button type="button" class="close btn-close"  style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid ">
            <div class="edit-profile">
              <div class="row d-flex justify-content-between">
                      <form  [formGroup]=" addCompany" novalidate [ngClass]="{ 'was-validated': addvalidateCompanyMerchant }" >
                        <div class="row">
                            <!-- <div class="mb-3 col-xl-4 col-md-4 ">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Company Name Ar' : (curentLang === 'ar' ? '  اسم الشركة عربي   ' : ' שם החברה הוא ערבי')}}
                               </label>
                                <input style="color: grey;" 
                                 class="form-control" maxlength="80" 
                                 type="text" formControlName="company_name_ar" 
                                  />
                              </div> -->
              
                              <!-- <div class="mb-3 col-xl-4 col-md-4 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Company Name En' : (curentLang === 'ar' ? '  اسم الشركة انجليزي   ' : 'שם החברה באנגלית ')}}
                                 </label>
                                  <input style="color: grey;" 
                                  class="form-control"
                                   type="text"
                                 
                                    formControlName="company_name_en"
                                   />
                                </div> -->
              
                                <div class="mb-3 col-xl-6 col-md-6 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Company Name' : (curentLang === 'ar' ? '  اسم الشركة    ' : '  שם החברה ')}}
                                    <span class="text-danger">*</span>
                                 </label>
                                  <input style="color: grey;" class="form-control" onlyAlphabets required=""
                                   type="text" 
                                   formControlName="company_name_he"  
                                  
                                  />
                                  <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter company name' : (curentLang === 'ar' ? '   الرجاء ادخال اسم الشركة ' : ' נא להזין את שם החברה  ')}}  </div>

                                </div>

                                <div class="mb-3 col-xl-6 col-md-6 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Company Phone' : (curentLang === 'ar' ? ' هاتف الشركة ' : 'טלפון של החברה')}}
                                 </label>
                                  <input style="color: grey;" class="form-control"
                                   type="text" 
                                   formControlName="company_phone"  
                                  
                                  />
                                   
                                </div>

                                <div class="mb-3 col-xl-6 col-md-6 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Company City He' : (curentLang === 'ar' ? ' مدينة الشركة عبري' : 'עיר חברה: איברי')}}
  
                                    <span class="text-danger">*</span>
                                 </label>
                                  <input style="color: grey;" class="form-control" onlyAlphabets required=""
                                   type="text" 
                                   formControlName="company_address_city"  
                                   maxlength="100"
                                  />
                                  <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter company city' : (curentLang === 'ar' ? '   الرجاء ادخال مدينة الشركة ' : ' נא להזין את עיר החברה ')}}  </div>
  
                                </div>
  
                                <div class="mb-3 col-xl-6 col-md-6 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Company City Ar' : (curentLang === 'ar' ? ' مدينة الشركة عربي' : 'חברת עיר ערבית')}}
                                    <span class="text-danger">*</span>
                                 </label>
                                  <input style="color: grey;" class="form-control"  onlyAlphabets required=""
                                   type="text" 
                                   formControlName="company_address_city_ar"  
                                   maxlength="100"
                                  />
                                  <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter company city' : (curentLang === 'ar' ? '   الرجاء ادخال مدينة الشركة ' : ' נא להזין את עיר החברה ')}}  </div>
  
                                </div>
  
                                <div class="mb-3 col-xl-6 col-md-6 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Company City En' : (curentLang === 'ar' ? ' مدينة الشركة انجليزي' : 'חברת עיר אנגלית')}}
                                    <span class="text-danger">*</span>
                                 </label>
                                  <input style="color: grey;" class="form-control"  onlyAlphabets required=""
                                   type="text" 
                                   formControlName="company_address_city_en"  
                                   maxlength="100"
                                  />
                                  <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter company city' : (curentLang === 'ar' ? '   الرجاء ادخال مدينة الشركة ' : ' נא להזין את עיר החברה ')}}  </div>
  
                                </div>

                                
                              <div class="mb-3 col-xl-6 col-md-6 ">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Company Street Name He' : (curentLang === 'ar' ? '  اسم شارع الشركة عبري' : 'שם הרחוב של החברה הוא עברי')}}
                                  <span class="text-danger">*</span>
                               </label>
                                <input style="color: grey;" class="form-control" onlyAlphabets required=""
                                 type="text" 
                                 formControlName="company_address_str"  
                                 maxlength="100"
                                />
                                <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter company address street' : (curentLang === 'ar' ? '   الرجاء ادخال أسم شارع الشركة ' : '  נא להזין את שם הרחוב של החברה')}}  </div>

                              </div>


                                 <div class="mb-3 col-xl-6 col-md-6 ">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Company Street Name Ar' : (curentLang === 'ar' ? '  اسم شارع الشركة عربي' : 'שם רחוב של החברה בערבית')}}
                                  <span class="text-danger">*</span>
                               </label>
                                <input style="color: grey;" class="form-control"
                                 type="text" 
                                 formControlName="company_address_str_ar"   onlyAlphabets required=""
                                 maxlength="100"
                                />
                                <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter company address street' : (curentLang === 'ar' ? '   الرجاء ادخال أسم شارع الشركة ' : '  נא להזין את שם הרחוב של החברה')}}  </div>

                              </div>

                              <div class="mb-3 col-xl-6 col-md-6 ">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Company Street Name En' : (curentLang === 'ar' ? '  اسم شارع الشركة انجليزي' : 'שם רחוב של החברה באנגלית')}}
                                  <span class="text-danger">*</span>
                               </label>
                                <input style="color: grey;" class="form-control"
                                 type="text" 
                                 formControlName="company_address_str_en"   onlyAlphabets required=""
                                 maxlength="100"
                                />
                                <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter company address street' : (curentLang === 'ar' ? '   الرجاء ادخال أسم شارع الشركة ' : '  נא להזין את שם הרחוב של החברה')}}  </div>

                              </div>

                              

                              <div class="mb-3 col-xl-6 col-md-6 ">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Company Building Number' : (curentLang === 'ar' ? '  رقم مبني الشركة' : 'מס בניין  ')}}
                                  <span class="text-danger">*</span>

                               </label>
                                <input style="color: grey;" class="form-control w-100" onlyAlphabets required=""
                                 type="number" 
                                 formControlName="company_Building_number"  
                                 maxlength="100"
                                />
                                <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter company building number' : (curentLang === 'ar' ? '   الرجاء ادخال  رقم مبني الشركة ' : '   נא להזין מספר בניין החברה    ')}}  </div>

                              </div>


                              <div class="mb-3 col-xl-6 col-md-6 ">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? '    Post number' : (curentLang === 'ar' ? ' الرقم البريدي  ' : ' מיקוד  ')}}
                                  <span class="text-danger">*</span>

                               </label>
                                <input style="color: grey;" class="form-control w-100"  onlyAlphabets required=""
                                 type="number" 
                                 formControlName="company_address_zip_code"  
                                 type="number" min="0"
                                />
                                <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter company Post number' : (curentLang === 'ar' ? '   الرجاء ادخال الرقم البريدي للشركة ' : '  נא להזין את המיקוד של החברה    ')}}  </div>

                              </div>

            
                              <div class="mb-3 col-xl-6 col-md-6">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Tax Register' : (curentLang === 'ar' ? 'رقم السجل الضريبي' : 'מס עוסק מורשה ') }}
                                  <span class="text-danger">*</span>

                                </label>
                                <input style="color: grey;"  onlyAlphabets required=""
                                       class="form-control w-100" 
                                       type="text"
                                       (keypress)="isNumber($event)"
                                       maxlength="9"
                                       max="9"
                                       min="0"
                                       inputmode="numeric" 
                                       formControlName="tax_register" 
                                       pattern="[0-9]{9}"
                                       title="{{ curentLang === 'en' ? 'Please enter exactly 9 digits' : (curentLang === 'ar' ? 'يرجى إدخال 9 أرقام فقط' : 'אנא הזן 9 ספרות בדיוק') }}" />
                                <div *ngIf="addCompany?.get('tax_register').invalid && (addCompany?.get('tax_register').dirty || addCompany?.get('tax_register').touched)" class="text-danger">
                                  {{ curentLang === 'en' ? 'Please enter exactly 9 digits' : (curentLang === 'ar' ? 'يرجى إدخال 9 أرقام فقط' : 'אנא הזן 9 ספרות בדיוק') }}
                                </div>
                              </div>
                              
                              

                              <div class="mb-3 col-xl-6 col-md-6 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Commercial Register' : (curentLang === 'ar' ? '  رقم السجل التجاري   ' : 'מס עוסק מורשה')}}
                                    <span class="text-danger">*</span>

                                 </label> 
                                  <input style="color: grey;"  onlyAlphabets required=""
                                  class="form-control w-100" type="number" 
                                   formControlName="commercial_register" min="0"
                                 
                                     />
                                     <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter company commercial register' : (curentLang === 'ar' ? '   الرجاء ادخال رقم السجل التجاري للشركة ' : '  נא להזין את מספר הרישום המסחרי של החברה  ')}}  </div>

                                </div>
                          
                                  <!---->

                                  
              
                        </div>
                  
                    
        
                          <div class="modal-footer">

                            <button type="button"  
                             class="btn btn-sm m-auto mt-2" 
                            style="background-color: #141414; color: #fff;"
                             (click)="addedData()" 
                             > 
                              {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
                              <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                          </button>
                      
                              <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                                  {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                                  <i class="fa fa-rotate-left mx-1"></i>
                              </button>
                      
                            
                      
                          </div>
                      </form>
                
               
               
            
              
            </div>
            
          </div>
        
        </div>
    </div>
   
</ng-template>




<!--add company by admin-->

<ng-template #contentAdmin let-modal>
  <div class="modal-header d-flex justify-content-between">
      <h6 class="modal-title"style="color: grey;">
              {{ curentLang === 'en' ? 'Add Company ' : (curentLang === 'ar' ? ' اضافة شركة ' : '  הוספת חברה')}} 
      </h6>
      <button type="button" class="close btn-close"  style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
      <div class="container-fluid ">
          <div class="edit-profile">
            <div class="row d-flex justify-content-between">
                    <form  [formGroup]=" addCompanyByAdmin" novalidate [ngClass]="{ 'was-validated': validate }">
                      <div class="row">
                          <!-- <div class="mb-3 col-xl-6 col-md-6 ">
                              <label class="form-label" style="color: grey;"> 
                                {{ curentLang === 'en' ? 'Company Name Ar' : (curentLang === 'ar' ? '  اسم الشركة عربي   ' : ' שם החברה הוא ערבי')}}
                             </label>
                              <input style="color: grey;" 
                               class="form-control" maxlength="80" 
                               type="text" formControlName="company_name_ar" 
                                />
                            </div> -->
            
                            <!-- <div class="mb-3 col-xl-6 col-md-6 ">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Company Name En' : (curentLang === 'ar' ? '  اسم الشركة انجليزي   ' : 'שם החברה באנגלית ')}}
                               </label>
                                <input style="color: grey;" 
                                class="form-control"
                                 type="text"
                               
                                  formControlName="company_name_en"
                                 />
                              </div> -->
            
                              <div class="mb-3 col-xl-6 col-md-6 ">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Company Name ' : (curentLang === 'ar' ? ' اسم الشركة ' : 'שם החברה')}}
                                  <span class="text-danger">*</span>
                               </label>
                                <input style="color: grey;" class="form-control" onlyAlphabets required=""
                                 type="text" 
                                 formControlName="company_name_he"  
                                
                                />
                                <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter company name' : (curentLang === 'ar' ? '   الرجاء ادخال اسم الشركة ' : ' נא להזין את שם החברה  ')}}  </div>

                              </div>

                              <div class="mb-3 col-xl-6 col-md-6 ">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Company Phone' : (curentLang === 'ar' ? ' هاتف الشركة ' : 'טלפון של החברה')}}
                               </label>
                                <input style="color: grey;" class="form-control"
                                 type="text" 
                                 formControlName="company_phone"  
                                
                                />
                                 
                              </div>

                              
                              <div class="mb-3 col-xl-6 col-md-6 ">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Merchant Name ' : (curentLang === 'ar' ? ' اسم التاجر  ' : 'שם סוחר ')}}
                               </label>
                                <ng-select formControlName="merchant_id" (change)="filterMerchant($event)" style="cursor: pointer;" onlyAlphabets required=""
                               placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
                               <ng-option *ngFor="let item of merchantInfoData; let i = index" [value]="item.merchant_id">
                                 {{ curentLang === 'en' ? item.merchant_name_en : (curentLang === 'ar' ? item.merchant_name_ar : item.merchant_name_he) }} 
                               </ng-option>
                               
                                </ng-select>
                                <div *ngIf="addCompanyByAdmin.get('merchant_id')?.errors?.required" class="text-danger">
                                <p>{{ curentLang === 'en' ? 'please chooce merchant name' : (curentLang === 'ar' ? '   الرجاء اختيار اسم التاجر ' : ' אנא בחר שם סוחר ')}} </p>  
                                </div>

                              </div>

                              <div class="mb-3 col-xl-6 col-md-6 ">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Company City He' : (curentLang === 'ar' ? ' مدينة الشركة عبري' : 'עיר חברה: איברי')}}

                                  <span class="text-danger">*</span>
                               </label>
                                <input style="color: grey;" class="form-control" onlyAlphabets required=""
                                 type="text" 
                                 formControlName="company_address_city"  
                                 maxlength="100"
                                />
                                <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter company city' : (curentLang === 'ar' ? '   الرجاء ادخال مدينة الشركة ' : ' נא להזין את עיר החברה ')}}  </div>

                              </div>

                              <div class="mb-3 col-xl-6 col-md-6 ">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Company City Ar' : (curentLang === 'ar' ? ' مدينة الشركة عربي' : 'חברת עיר ערבית')}}
                                  <span class="text-danger">*</span>
                               </label>
                                <input style="color: grey;" class="form-control"  onlyAlphabets required=""
                                 type="text" 
                                 formControlName="company_address_city_ar"  
                                 maxlength="100"
                                />
                                <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter company city' : (curentLang === 'ar' ? '   الرجاء ادخال مدينة الشركة ' : ' נא להזין את עיר החברה ')}}  </div>

                              </div>

                              <div class="mb-3 col-xl-6 col-md-6 ">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Company City En' : (curentLang === 'ar' ? ' مدينة الشركة انجليزي' : 'חברת עיר אנגלית')}}
                                  <span class="text-danger">*</span>
                               </label>
                                <input style="color: grey;" class="form-control"  onlyAlphabets required=""
                                 type="text" 
                                 formControlName="company_address_city_en"  
                                 maxlength="100"
                                />
                                <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter company city' : (curentLang === 'ar' ? '   الرجاء ادخال مدينة الشركة ' : ' נא להזין את עיר החברה ')}}  </div>

                              </div>

                              

                              <div class="mb-3 col-xl-6 col-md-6 ">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Company Street Name He' : (curentLang === 'ar' ? '  اسم شارع الشركة عبري' : 'שם הרחוב של החברה הוא עברי')}}
                                  <span class="text-danger">*</span>
                               </label>
                                <input style="color: grey;" class="form-control" onlyAlphabets required=""
                                 type="text" 
                                 formControlName="company_address_str"  
                                 maxlength="100"
                                />
                                <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter company address street' : (curentLang === 'ar' ? '   الرجاء ادخال أسم شارع الشركة ' : '  נא להזין את שם הרחוב של החברה')}}  </div>

                              </div>

                              <div class="mb-3 col-xl-6 col-md-6 ">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Company Street Name Ar' : (curentLang === 'ar' ? '  اسم شارع الشركة عربي' : 'שם רחוב של החברה בערבית')}}
                                  <span class="text-danger">*</span>
                               </label>
                                <input style="color: grey;" class="form-control"
                                 type="text" 
                                 formControlName="company_address_str_ar"   onlyAlphabets required=""
                                 maxlength="100"
                                />
                                <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter company address street' : (curentLang === 'ar' ? '   الرجاء ادخال أسم شارع الشركة ' : '  נא להזין את שם הרחוב של החברה')}}  </div>

                              </div>

                              <div class="mb-3 col-xl-6 col-md-6 ">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Company Street Name En' : (curentLang === 'ar' ? '  اسم شارع الشركة انجليزي' : 'שם רחוב של החברה באנגלית')}}
                                  <span class="text-danger">*</span>
                               </label>
                                <input style="color: grey;" class="form-control"
                                 type="text" 
                                 formControlName="company_address_str_en"   onlyAlphabets required=""
                                 maxlength="100"
                                />
                                <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter company address street' : (curentLang === 'ar' ? '   الرجاء ادخال أسم شارع الشركة ' : '  נא להזין את שם הרחוב של החברה')}}  </div>

                              </div>

                              <div class="mb-3 col-xl-6 col-md-6 ">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Company Building Number' : (curentLang === 'ar' ? '  رقم مبني الشركة' : 'מס בניין  ')}}
                                  <span class="text-danger">*</span>

                               </label>
                                <input style="color: grey;" class="form-control w-100" onlyAlphabets required=""
                                 type="number" 
                                 formControlName="company_Building_number"  
                                 maxlength="100"
                                />
                                <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter company building number' : (curentLang === 'ar' ? '   الرجاء ادخال  رقم مبني الشركة ' : '   נא להזין מספר בניין החברה    ')}}  </div>

                              </div>


                              <div class="mb-3 col-xl-6 col-md-6 ">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? '    Post number' : (curentLang === 'ar' ? ' الرقم البريدي  ' : ' מיקוד  ')}}
                                  <span class="text-danger">*</span>

                               </label>
                                <input style="color: grey;" class="form-control w-100"  onlyAlphabets required=""
                                 type="number" 
                                 formControlName="company_address_zip_code"  
                                 type="number" min="0"
                                />
                                <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter company Post number' : (curentLang === 'ar' ? '   الرجاء ادخال الرقم البريدي للشركة ' : '  נא להזין את המיקוד של החברה    ')}}  </div>

                              </div>

            
                              <div class="mb-3 col-xl-6 col-md-6">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Tax Register' : (curentLang === 'ar' ? 'رقم السجل الضريبي' : 'מס עוסק מורשה ') }}
                                  <span class="text-danger">*</span>

                                </label>
                                <input style="color: grey;"  onlyAlphabets required=""
                                       class="form-control w-100" 
                                       type="text"
                                       (keypress)="isNumber($event)"
                                       maxlength="9"
                                       max="9"
                                       min="0"
                                       inputmode="numeric" 
                                       formControlName="tax_register" 
                                       pattern="[0-9]{9}"
                                       title="{{ curentLang === 'en' ? 'Please enter exactly 9 digits' : (curentLang === 'ar' ? 'يرجى إدخال 9 أرقام فقط' : 'אנא הזן 9 ספרות בדיוק') }}" />
                                <div *ngIf="addCompanyByAdmin?.get('tax_register').invalid && (addCompanyByAdmin?.get('tax_register').dirty || addCompanyByAdmin?.get('tax_register').touched)" class="text-danger">
                                  {{ curentLang === 'en' ? 'Please enter exactly 9 digits' : (curentLang === 'ar' ? 'يرجى إدخال 9 أرقام فقط' : 'אנא הזן 9 ספרות בדיוק') }}
                                </div>
                              </div>
                              
                              

                              <div class="mb-3 col-xl-6 col-md-6 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Commercial Register' : (curentLang === 'ar' ? '  رقم السجل التجاري   ' : 'מס עוסק מורשה')}}
                                    <span class="text-danger">*</span>

                                 </label> 
                                  <input style="color: grey;"  onlyAlphabets required=""
                                  class="form-control w-100" type="number" 
                                   formControlName="commercial_register" min="0"
                                 
                                     />
                                     <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter company commercial register' : (curentLang === 'ar' ? '   الرجاء ادخال رقم السجل التجاري للشركة ' : '  נא להזין את מספר הרישום המסחרי של החברה  ')}}  </div>

                                </div>

                           
                                <!---->

                                
            
                      </div>
                
                  
      
                        <div class="modal-footer">

                          <button type="button"   
                           class="btn btn-sm m-auto mt-2" 
                          style="background-color: #141414; color: #fff;"
                           (click)="addedDataByAadmin()"   [disabled]="addCompanyByAdmin.invalid"
                           > 
                            {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
                            <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                        </button>
                    
                            <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                                {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                                <i class="fa fa-rotate-left mx-1"></i>
                            </button>
                    
                          
                    
                        </div>
                    </form>
              
             
             
          
            
          </div>
          
        </div>
      
      </div>
  </div>
 
</ng-template>





<!--add Permissions-->

<ng-template #content3 let-modal>
  <div class="modal-header d-flex justify-content-between">
      <h6 class="modal-title"style="color: grey;">
          {{ curentLang === 'en' ? 'Adding an accounting office to the company' : (curentLang === 'ar' ? 'اضافة مكتبة محاسبة للشركة' : 'הוספת ספריית הנהלת חשבונות לחברה')}}
      </h6>
      <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <div class="container-fluid ">
          <div class="edit-profile">
            <div class="row d-flex justify-content-between">
              <form [formGroup]="addPermissionForm">
                <div class="row">
                  <div class="mb-3 col-xl-12 col-md-12">
                    <label class="form-label" style="color: grey;">
                      {{ curentLang === 'en' ? 'Office Accountant Name' : (curentLang === 'ar' ? 'اسم مكتب المحاسبة' : 'שם משרד רואי החשבון') }}
                    </label>
                    <ng-select formControlName="companyUser" [(ngModel)]="selectedOffice" (change)="filterSelected(selectedOffice)" [multiple]="true"
                    style="cursor: pointer;" placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחירה') }}">
                      <ng-option *ngFor="let item of activeAccountantOfficeData; let i = index" [value]="item">
                        <span>{{item?.office_name}}  </span>
                        <span style="color: transparent;">{{item.office_phone}}</span>
                      </ng-option>
                    </ng-select>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-sm m-auto mt-2" style="background-color: #141414; color: #fff;"
                  [disabled]="addPermissionForm.invalid" (click)="addedPermission()">
                    {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? 'تاكيد' : 'אישור') }}
                    <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                  </button>
                  <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                    {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? 'رجوع' : 'חזרה') }}
                    <i class="fa fa-rotate-left mx-1"></i>
                  </button>
                </div>
              </form>
              
                 
              
          </div>
          
        </div>
      
      </div>
  </div>
 
</ng-template>





<ng-template #content4 let-modal>
  <div class="modal-header">
    <h4 class="modal-title"></h4>
    <button type="button" class="close btn-close"  style="color: grey;"  aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-center text-danger">
      <i class="fa fa-trash text-danger"></i>
      {{curentLang === 'en' ? ' Are you sure you want to contact the IRS ?' : (curentLang === 'ar' ? ' هل تريد بالتأكيد الربط مع مصلحة الضرائب ؟ ' : 'האם אתה בטוח שאתה רוצה ליצור קשר עם מס הכנסה?')}}
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-sm m-auto mt-2" style="background-color: #141414; color: #fff;"
    (click)="ConnectWithTaxGovernment()">
      {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? 'تاكيد' : 'אישור') }}
      <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
    </button>
    <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
      {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? 'رجوع' : 'חזרה') }}
      <i class="fa fa-rotate-left mx-1"></i>
    </button>
  </div>
</ng-template>



   <!--Delete company-->

   <ng-template #dataDeleted let-modal>
    <div class="modal-header">
        <h4 class="modal-title"></h4>
        <button type="button" class="close btn-close"  style="color: grey;"  aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p class="text-center text-danger"> <i class="fa fa-trash text-danger"></i>
          {{curentLang === 'en' ? 'Are you sure you want to delete it?' : (curentLang === 'ar' ? '  هل تريد بالتأكيد حذفه؟ ' : ' هل تريد بالتأكيد حذفه؟') }}
        </p>
    </div>
    <div class="modal-footer">

      <button type="button" 
       class="btn btn-sm m-auto mt-2" 
      style="background-color: #141414; color: #fff;"
       (click)="deleteCompanyByCompanyId()"
       > 
        {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
        <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
    </button>

        <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
            {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
            <i class="fa fa-rotate-left mx-1"></i>
        </button>

      

    </div>
</ng-template>
 



<!--export sales report-->

<ng-template #content5 let-modal>
  <div class="modal-header d-flex justify-content-between">
      <h6 class="modal-title"style="color: grey;">
          {{ curentLang === 'en' ? 'Issuing a sales report' : (curentLang === 'ar' ? ' اصدار تقرير مبيعات ' : 'הוצאת דו"ח מכירה')}}
      </h6>
      <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <div class="container-fluid ">
          <div class="edit-profile">
            <div class="row d-flex justify-content-between">
              <form [formGroup]="reportsForm"   >
  
        
                <div class="row d-flex justify-content-between ">
                  <div class="col-md-4 mt-2 "  >
                    <h6 class="form-label px-1 " style="color: grey;"> 
                      {{ curentLang === 'en' ? 'Period' : (curentLang === 'ar' ? 'الفترة' : '  תקופה ')}}
                    </h6>
                    <div class="px-1">
                      <select class="form-control  px-1  form-select" style="cursor: pointer;" (change)="filterMonth($event)">
                        <option [value]="1">{{ curentLang === 'en' ? 'Current month' : (curentLang === 'ar' ? 'الشهر الحالي' : 'חודש נוכחי  ') }}</option>
                        <option [value]="2">{{ curentLang === 'en' ? 'Last month' : (curentLang === 'ar' ? 'الشهر الماضي' : '  חודש קודם') }}</option>
                        <option [value]="5">{{ curentLang === 'en' ? 'Last two month' : (curentLang === 'ar' ? ' أخر شهران ' : ' שני חודשים קודמים  ') }}</option>
                        <option [value]="3">{{ curentLang === 'en' ? 'Start of the year' : (curentLang === 'ar' ? 'من بداية العام' : ' מתחילת שנה  ') }}</option>
                        <option [value]="4">{{ curentLang === 'en' ? 'Last year' : (curentLang === 'ar' ? 'العام الماضي' : ' שנה קודמת  ') }}</option>
                      </select>
                    </div>
                 
                  </div>
                
                  <div class="col-md-4 ">
                    <h6 for="fromDate" class="ml-2" style="color: grey;">
                      {{curentLang === 'en' ? 'From Date' : (curentLang === 'ar' ? 'من تاريخ' : '  מתאריך')}}
                    </h6>
                    <div  >
                      <p-calendar [(ngModel)]="fromDate" style="display: block; width: 100%;"
                       showIcon="true" (ngModelChange)="onFromDateChange($event)"
                          formControlName="startDate" 
                          dateFormat="dd/mm/yy"
                           placeholder="DD/MM/YYYY"
                            >
                          </p-calendar>
              
                    </div>
                  </div>
              
                    <div class="col-md-4">
                      <h6 for="toDate"  style="color: grey;">
                       {{curentLang === 'en' ? 'To Date' : (curentLang === 'ar' ? 'الي تاريخ' : ' עד תאריך  ')}}
                     </h6>
                     <div class="">
                     <p-calendar 
                     style="display: block; width: 100%;"
                       [(ngModel)]="toDate" 
                       showIcon="true" 
                       (ngModelChange)="onToDateChange($event)" 
                       formControlName="endDate" 
                       dateFormat="dd/mm/yy" 
                       placeholder="DD/MM/YYYY">
                     </p-calendar>
                    </div>
                 </div>
                  
                 
                  
                   
                  
                  <div class="col-md-12 mt-4 " style="padding-top: 6px;">
                    <div class="form-group search  ">
                      <button class="btn btn-sm mx-1  mt-2 " style="background-color: #141414; color: #fff;" 
                      [disabled]="!fromDate || !toDate"
                      (click)="confirmAction()">
                        <i class="fa fa-download mx-1"></i>
                        {{curentLang === 'en' ? 'Download Reports' : (curentLang === 'ar' ? 'تحميل التقارير' : 'הורד דוחות')}}
                      </button>
                    </div>
                  </div>
                </div>
                   
                   
                  
                    </form>
              
                 
              
          </div>
          
        </div>
      
      </div>
  </div>
 
</ng-template>